import COLORS from 'const/colors';

const StarHalf = ({ fillColor = COLORS.PRIMARY.JOY_MUSTARD }) => (
  <svg id="star-half" viewBox="0 0 19 18">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-154.000000, -83.000000)">
        <g transform="translate(154.000000, 83.000000)">
          <path
            d="M9,14.9983037 L9.0015564,14.9973985 L14.2431108,17.7559529 C14.3987732,17.8461423 14.5470236,17.8912363 14.6878595,17.8912363 C14.8361124,17.8912363 14.9491507,17.8367477 15.0269819,17.7277688 C15.1048131,17.61879 15.1437287,17.4853867 15.1437287,17.3275552 C15.1437287,17.2298499 15.1400227,17.1546932 15.1326107,17.1020828 L14.1763976,11.4652717 L18.2125024,7.47441002 C18.4052274,7.27900046 18.5015886,7.09862396 18.5015886,6.93327546 C18.5015886,6.65519292 18.2940421,6.48233168 17.878939,6.41468923 L12.2973307,5.5917145 L9.54125588,0.461218322 C9.40041751,0.153072185 9.22393198,0.00328063965 9.0015564,0.00328063965 L9,0.00328308745 L9,14.9983037 Z"
            fill={COLORS.GRAY.G7}
          />
          <path
            d="M9,14.9983037 L4.25847781,17.7559529 C4.09540338,17.8461423 3.94715547,17.8912363 3.81372913,17.8912363 C3.65806671,17.8912363 3.5413199,17.8367477 3.4634887,17.7277688 C3.38565749,17.61879 3.34674189,17.4853867 3.34674189,17.3275552 C3.34674189,17.2824606 3.35415391,17.2073039 3.36898044,17.1020828 L4.32519099,11.4652717 L0.277968488,7.47441002 C0.0926552745,7.27148267 0,7.09110869 0,6.93327546 C0,6.65519292 0.207547809,6.48233168 0.622649653,6.41468923 L6.20425798,5.5917145 L8.45161899,0.461218322 C8.59212867,0.153791354 8.77830949,0.00398068662 9,0.00328308745 L9,14.9983037 Z"
            id="Combined-Shape"
            fill={fillColor}
          />
        </g>
      </g>
    </g>
  </svg>
);
export default StarHalf;
